 
.privacy-content-p{
  text-align: justify;
}
@media (min-width: 448px) {
    #PolitiquedeconfidentialiteContent{
      margin: 100px;
    }
  }
  
  @media (max-width: 449px) {
    #PolitiquedeconfidentialiteContent{
      margin: 10px;
    }
  }